import Image from 'next/image'
import styled, { keyframes } from 'styled-components'

const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 3))
  }
`

export const List = styled.div<{ $slideLength: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: ${slideAnimation} 20s linear infinite;
`

export const ImageStyled = styled(Image)`
  padding-inline: 75px;
  width: auto;

  ${p => p.theme.mediaQueries.tablet} {
    padding-inline: 32px;
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding-inline: 25px;
  }
`
