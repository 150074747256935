import type { FC } from 'react'

import type { PaymentMethodsComponentProps } from '../../PaymentMethodsBlock'
import { ImageStyled, List } from './styles'

export const AnimatedPaymentMethods: FC<PaymentMethodsComponentProps> = ({ paymentMethods }) => {
  if (!paymentMethods) return

  const longArray = [...paymentMethods, ...paymentMethods, ...paymentMethods]
  const slideLength = paymentMethods.length

  return (
    <List $slideLength={slideLength}>
      {longArray?.map((method, index) => (
        <ImageStyled alt={method.label} height="40" key={index} loading="lazy" src={method.image} width="100" />
      ))}
    </List>
  )
}
